import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


export default function CookiesDisclaimer(props) {
  const [open, setOpen] = React.useState(true);

  const autocompleteTheme = createMuiTheme({
    palette: {
      text: {
        primary: "#808080",
        secondary: "#808080",
        info: "#808080"
      }, 
    },
    typography: {
      fontFamily: [
        'Nunito'
      ],
    },
  });

  const handleClose = () => {
    localStorage.setItem('cookies_disclaimer_dismissed', true)
    setOpen(false);
  };

  return (
    <div>
      <ThemeProvider theme={autocompleteTheme}>
        <Dialog
          open={
            (props.showCookiesDisclaimer === true && open) ? true : false
          }
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle style={{fontFamily:"Nunito"}} id="alert-dialog-slide-title">{"Deep Grey Markets - Cookie Policy"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              We use cookies to improve user experience, and analyze website traffic. For these reasons, we may share your site usage data with our analytics partners. By continuing to the site, you consent to store on your device all the technologies described in our Cookie Policy. Please read our Terms and Conditions and Privacy Policy for full details.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Proceed
            </Button>
          </DialogActions>
        </Dialog>
      </ThemeProvider>
    </div>
  );
}