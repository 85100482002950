import React from "react";
import { Col, Container, Row } from "reactstrap";
import * as types from "../redux/constants";

export default class Footer extends React.Component {
  render(){
    return(
      <footer className="footer">
        <Container fluid>
          <Row className="text-muted">
            <Col xs="6" className="text-left">
              <ul className="list-inline">
                <li className="list-inline-item">
                  <a className="text-muted" href="tel:+447798875227">
                    +44-7798-875227
                  </a>
                </li>
                <li className="list-inline-item">
                  <a className="text-muted" href="mailto:enrico@deepgreyresearch.com">
                    Contact Us
                  </a>
                </li>
                <li className="list-inline-item">
                  <a className="text-muted" href={types.REACT_ROOT + types.TERMS}>
                    Terms of Service
                  </a>
                </li>
              </ul>
            </Col>
            <Col xs="6" className="text-right">
              <p className="mb-0">
                &copy; {new Date().getFullYear()} -{" "}
                <span href="/" className="text-muted">
                  Deep Grey Markets LLC
                </span>
              </p>
            </Col>
          </Row>
        </Container>
      </footer>
    );
  }
}