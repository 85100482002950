import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/analytics";

const firebaseConfig = {
    apiKey: "AIzaSyDhxRWrP1jiR9N0kM_d1qn-D5i6H4rbR0Y",
    authDomain: "deepgreymarkets.firebaseapp.com",
    databaseURL: "https://deepgreymarkets.firebaseio.com",
    projectId: "deepgreymarkets",
    storageBucket: "deepgreymarkets.appspot.com",
    messagingSenderId: "633241147365",
    appId: "1:633241147365:web:c15f191ac9364c2c97410c",
    measurementId: "G-3TQ89WQ8Z0"
};

firebase.initializeApp(firebaseConfig);
export const auth = firebase.auth();
export const firestore = firebase.firestore();
export const analytics = firebase.analytics();
firebase.analytics();
