import React from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Container,
  Row
} from "reactstrap";

const Privacy = () => (
  <Card className="p3 p-lg-4">
    <CardHeader>
      <CardTitle tag="h5" className="mb-0">
        Privacy Policy
      </CardTitle>
    </CardHeader>
    <CardBody>
      <div id="privacy">
        <p className="mb-2">
          Please read the Deep Grey Markets Privacy Policy below as it reflects Deep Grey Market's methods of data collection, aggregation, and destruction. If you have any questions or concerns you can reach the Deep Grey Markets support team at: cody@deepgreymarkets.com
          <br></br><br></br>
          COLLECTION OF INFORMATION
          <br></br>
          When you browse the Website, provide your information, or contact us your information may be collected.
          This collection of data may be stored in the aggregate or individualized, depending on what is collected.
          For specific information that you provide for your account, including age, email address, name, personal address, phone number, or other information that can be Personally Identifying Information (“PII”), we do our best to safeguard the information and utilize it solely to fulfill the purpose of our website and services.
          <br></br><br></br>
          CONSENT
          <br></br>
          When you provide us with personal information for a specific purpose you consent to our collecting such information and our using it for that purpose as well as other, future and unidentified purposes. If you change your mind after opting in, you may withdraw your consent for us to contact you, for the continued collection, use or disclosure of your information, at any time, by contacting us at enrico@deepgreyresearch.com.
          <br></br><br></br>
          DISCLOSURE
          <br></br>
          We may disclose your personal information if we are required by law to do so or if you violate our Terms and Conditions. We will disclose your information if law enforcement demands it or if we reasonably believe you are engaged in criminal activity or the life, health, and safety of a person is in danger. When possible we will notify you of requests for your information, unless prohibited by law or court order.
          <br></br><br></br>
          TRACKERS
          <br></br>
          Our website may utilize a variety of trackers or retargeting apps. Such third-party materials may have built in trackers, broadcasting materials, scraping programs, or other programs that are not covered by this Policy.
          <br></br><br></br>
          THIRD-PARTY ENTITIES
          <br></br>
          Should you leave our Application or are redirected to a third-party website or application, you are no longer governed by this Privacy Policy. As such we will not be liable for any data or personal information that may be exposed on third party platforms. When you click on links on our website or services, they may direct you away from our site. We are not responsible for the privacy practices of other sites and strongly encourage you to read their privacy statements.
          <br></br><br></br>
          SECURITY
          <br></br>
          To protect your personal information, we take reasonable precautions and follow industry best practices to make sure it is not inappropriately lost, misused, accessed, disclosed, altered or destroyed. Any PII will be protected to the best of our ability and only disclosed to those who need it.
          <br></br><br></br>
          CHANGES TO THIS PRIVACY POLICY
          <br></br>
          We reserve the right to modify this privacy policy at any time, so please review it frequently. Changes and clarifications will take effect immediately upon their posting on the website.
          If we make material changes to this policy, we will notify you here that it has been updated, so that you are aware of what information we collect, how we use it, and under what circumstances, if any, we use and/or disclose it.
          If our website or services are acquired or merged with another company, your information may be transferred to the new owners so that we may continue to provide such services to you. In such instance we will notify you of the transfer of material.
          <br></br><br></br>
          QUESTIONS AND CONTACT INFORMATION
          <br></br>
          If you would like to: access, correct, amend or delete any personal information we have about you, register a complaint, or simply want more information please contact our support staff at enrico@deepgreyresearch.com
          <br></br><br></br>
          COOKIE POLICY
          <br></br>
          As reflected above, we try to be transparent and honest with our customers. To that end, our policy is to make you aware of what cookies we use and how they function so that you can make an informed choice to permit cookies or not.
          <br></br><br></br>
          COOKIES
          <br></br>
          Cookies are electronic bits that store on your electronic system, be it computer, phone, or pad. These bits track your movements and send validation credentials as needed when you are utilizing the website. If you’ve ever pressed “Remember Me” on your email login, you consented to the long term storage of cookies that let the email provider know that the person accessing the email, from that device, wanted to be remembered and permitted access.
          Specific cookies that are utilized are those associated with Google Analytics for the storage of session data, including what page you travel to within our website, how long you were on the site, and how you interacted with the materials presented on the site. These cookies are aggregators in nature and do not specifically follow or acquire personal information. We also utilize Facebook Pixel trackers, in order to retarget users that click on our ads. The purpose of these trackers is to assist users in traversing from the site on which the ad is displayed to our site, and logs aggregate data of how many persons come from which sites. This data is aggregated and utilized in order to acquire and interpret market information.
          <br></br><br></br>
          DESTRUCTION
          <br></br>
          Our cookies self-destruct when a set period of time has elapsed.  However, should cookies and their use reveal personal information about you to use that you did not consent to, our policy is to go ahead and destroy the information. The cookies are for our business use in the aggregate and are not meant to linger or follow you all over the internet.
          <br></br><br></br>
          QUESTIONS
          <br></br>
          If in doubt, please email us at enrico@deepgreyresearch.com and we’ll be glad to address your questions or concerns!
        </p>
      </div>
    </CardBody>
  </Card>
);

const TermsOfService = () => (
  <Container fluid className="p-0">
    <h1 className="h3 mb-3">Terms of Service</h1>
    <Row>
      <Col lg="12">
        <Privacy />
      </Col>
    </Row>
  </Container>
);

export default TermsOfService;
