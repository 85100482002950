import React from "react";
import * as types from "./../redux/constants";

import { connect } from "react-redux";
import { NavLink, withRouter } from "react-router-dom";
import dgm_logo_light from "../assets/img/logos/DGM_logo_full_white.png"

import { Badge, Collapse } from "reactstrap";
import PerfectScrollbar from "react-perfect-scrollbar";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-solid-svg-icons";

import { investor_routes, admin_routes } from "../routes/index";

const SidebarCategory = withRouter(
  ({
    name,
    badgeColor,
    badgeText,
    icon: Icon,
    isOpen,
    children,
    onClick,
    location,
    to
  }) => {
    const getSidebarItemClass = path => {
      return location.pathname.indexOf(path + "/") !== -1 ||
        (location.pathname === "/" && path === "/dashboard") ? "active" : "";
    };

    return (
      <>
        <li 
          className={"sidebar-item " + getSidebarItemClass(to)}
        >
          <span
            data-toggle="collapse"
            className={"sidebar-link " + (!isOpen ? "collapsed" : "")}
            onClick={onClick}
            aria-expanded={isOpen ? "true" : "false"}
          >
            <Icon size={18} className="align-middle mr-3" />
            <span className="align-middle">{name}</span>
            {badgeColor && badgeText ? (
              <Badge color={badgeColor} size={18} className="sidebar-badge">
                {badgeText}
              </Badge>
            ) : null}
          </span>
          <Collapse isOpen={isOpen}>
            <ul id="item" className={"sidebar-dropdown list-unstyled"}>
              {children}
            </ul>
          </Collapse>
        </li>
        {name === 'UST Futures' && (
          <hr 
            style={{
              'borderTop': '1px solid #888',
              'width': '80px',
              'marginTop': '20px',
              'marginBottom': '20px'
            }}
          />
        )}
      </>
    );
  }
);

const SidebarItem = withRouter(
  ({ name, badgeColor, badgeText, icon: Icon, location, to }) => {
    const getSidebarItemClass = path => {
      return location.pathname === path ? "active" : "";
    };

    return (
      <li 
        className={"sidebar-item " + getSidebarItemClass(to)}
        style={{'marginTop': name === 'Cockpit' ? '15px' : '0px'}}
        onClick={() => window.location.reload(true)}
      >
        <NavLink to={to} className="sidebar-link" activeClassName="active">
          {Icon ? <Icon size={18} className="align-middle mr-3" /> : null}
          {name}
          {badgeColor && badgeText ? (
            <Badge color={badgeColor} size={18} className="sidebar-badge">
              {badgeText}
            </Badge>
          ) : null}
        </NavLink>
      </li>
    );
  }
);

class Sidebar extends React.Component {
  
  componentDidMount() {
    this.updateSidebarImage();   
    this.interval = setInterval(() => {
      var ping_status = this.parsePingStatus(localStorage.getItem('ping_status'));
      if (ping_status === null) ping_status = false;
      this.setState({pingStatus: ping_status});
    }, 1000);
  }

  componentWillMount() {
    this.checkDGMPrivilege();
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  checkDGMPrivilege() {
    const permissions = localStorage.getItem('permissions');
    if (permissions >= types.MIN_ADMIN_PERMISSIONS) {
      this.routes = admin_routes
    } 
    else if (permissions >= 0) {
      this.routes = investor_routes
    }
  }

  updateSidebarImage() {
    if (localStorage.getItem('photoPath') !== 'null') {
      document.getElementById("sidebar-photo").src = localStorage.getItem('photoPath');
    }
  }

  toggle = index => {
    // collapse all elements
    // Object.keys(this.state).forEach(
    //   item =>
    //     this.state[index] ||
    //     this.setState(() => ({
    //       [item]: false
    //     }))
    // );

    // toggle selected element
    this.setState(state => ({
      [index]: !state[index]
    }));  
  };

  parsePingStatus = pingStatus => {
    if (typeof(pingStatus) === 'string') {
      return (pingStatus === 'true');
    }
    else {
      return pingStatus;
    }
  }

  UNSAFE_componentWillMount() {

    // open collapse element that matches current url
    const pathName = this.props.location.pathname;
    this.routes.forEach((route, index) => {
      const isActive = pathName.indexOf(route.path + "/") === 0;
      const isOpen = route.open;
      const isHome = route.containsHome && pathName === "/" ? true : false;
      this.setState(() => ({
        [index]: isActive || isOpen || isHome
      }));
    });
  }

  render() {
    const { sidebar, layout } = this.props;

    return (
      <nav
        className={
          "sidebar" +
          (!sidebar.isOpen ? " toggled" : "") +
          (sidebar.isSticky ? " sidebar-sticky" : "")
        }
      >
        <div className="sidebar-content">
          <PerfectScrollbar>
            <a className="sidebar-brand" href={types.REACT_ROOT + types.ALL_MARKETS + types.CONTROL}>
              <img className="align-middle text-primary" width="160vw" src={dgm_logo_light} alt="Sidebar Content"/>{" "}
            </a>
            <ul className="sidebar-nav">
              {this.routes.map((category, index) => {
                return (
                  <React.Fragment key={index}>
                    {category.header ? (
                      <li className="sidebar-header">{category.header}</li>
                    ) : null}

                    {category.children ? (
                      <SidebarCategory
                        name={category.name}
                        badgeColor={category.badgeColor}
                        badgeText={category.badgeText}
                        icon={category.icon}
                        to={category.path}
                        isOpen={this.state[index]}
                        onClick={() => this.toggle(index)}
                      >
                        {category.children.map((route, index) => (
                          <SidebarItem
                            key={index}
                            name={route.name}
                            to={route.path}
                            badgeColor={route.badgeColor}
                            badgeText={route.badgeText}
                          />
                        ))}
                      </SidebarCategory>
                    ) : (
                      <SidebarItem
                        name={category.name}
                        to={category.path}
                        icon={category.icon}
                        badgeColor={category.badgeColor}
                        badgeText={category.badgeText}
                      />
                    )}
                  </React.Fragment>
                );
              })}
            </ul>

            {!layout.isBoxed && !sidebar.isSticky ? (
              <div className="sidebar-bottom d-none d-lg-block">
                <div className="media">
                  <img
                    id="sidebar-photo"
                    className="rounded-circle mr-3"
                    src={null}
                    alt={'Account Profile'}
                    width="40"
                    height="40"
                  />
                  <div className="media-body">
                    <h5 className="mb-1">{
                      (localStorage.getItem('displayName') !== 'null') ? localStorage.getItem('displayName') : "Guest Account"
                    }</h5>
                    <div>
                      {
                        (this.state.pingStatus !== false) ? 
                        <>
                          <FontAwesomeIcon
                            icon={faCircle}
                            style={{color: '#4BB543'}}
                          />{" "}
                          Online{localStorage.getItem('permissions') < types.MIN_ADMIN_PERMISSIONS ? ' (Delayed)' : ''} 
                        </> :
                        <>
                          <FontAwesomeIcon
                            icon={faCircle}
                            style={{color: '#CC0000'}}
                          />{" "}
                          Offline
                        </>
                      }     
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
          </PerfectScrollbar>
        </div>
      </nav>
    );
  }
}

export default withRouter(
  connect(store => ({
    sidebar: store.sidebar,
    layout: store.layout
  }))(Sidebar)
);
